<template>

  <div class="full-height">

    <VueHeader :key="menuContent" v-if="$route.name!=='404'" />

    <RouterView />

  </div>

  <VueFooter :footerData="menuContent" :siteData="siteData" v-if="$route.name!=='404'" />
</template>
<script  >
import VueHeader from "@/components/VueHeader.vue";
import { useMenuStore, useSiteStore } from "@/store";

export default {
  name: "App",
  components: {
    VueHeader,
  },

  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        to + from;

        console.log( useSiteStore().siteInformation[0]?.siteName)
        document.title = useSiteStore().siteInformation[0]?.siteName;
      },
    },
  },

  data() {
    return {
      language: "en",
    };
  },

  computed: {
    siteLogo() {
      return useSiteStore().siteInformation[0].logo;
    },
    siteData() {
      return useSiteStore().siteInformation[0];
    },
    menuContent() {
      return useMenuStore().menuData;
    },
  },
  methods: {
    languageChange(value) {
      this.language = value;
    },
  },
};
</script>




<style lang="scss">
@import "@/assets/base.scss";

html,
body {
  font-family: "Roboto", serif !important;
  // font-size: 18px;

  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.user-box {
  height: 420px;
  width: 470px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
  margin-top: 60px;
}

.full-height {
  min-height: 100vh - 10vh;
}
</style>
