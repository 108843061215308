import { isArray } from "lodash";

document.addEventListener("click", function handleClickOutsideBox(event) {
    // Handle Search Bar Navigation
    const navMenu = document.getElementById("navbar-toggler");
    const navMenuParent = document.getElementById("navbarDarkDropdownMenuLink");
    if (!navMenu.contains(event.target) &&
        !navMenuParent.contains(event.target)
    ) {
        var element = document.getElementById("navmenu");
        element.classList.remove("show");
    }

    // Handle Social Media Toggling
    const smButton = document.getElementById("smButton");
    var smIcons = document.getElementById("smIcons");
    smButton.contains(event.target) && smIcons.classList.contains("d-none") ?
        smIcons.classList.remove("d-none") :
        smIcons.classList.add("d-none");
});

const createRouteObject = ({ path, name, component, children, ...rest }) => {
    if (!children) {
        return {...rest, path, name, component };
    }
    return {...rest, path, name, component, children };
};

// Create Child Routes
const createChildren = ({ menu, parentSlug }) => {
    const children = menu.reduce((prev, cur) => {
        if (cur.parentSlug && cur.parentSlug === parentSlug) {
            return [
                ...prev,
                createRouteObject({
                    GUID: cur.id,
                    Title: cur.name,
                    path: `/${cur.locale}/${parentSlug}/${cur.link || cur.slug}/`,
                    name: cur.name,
                    link: cur.slug,
                    displayInHeader: cur.displayInHeader,
                    language: cur.locale,
                    externalUrl: cur.externalUrl,
                    displayInFooter: cur.displayInFooter,
                    component: () =>
                        import ("../views/Page.vue"),
                }),
            ];
        }
        return prev;
    }, []);

    // if (!children.length) return null;
    return children;
};

// Create Parent Routes
export const createMenu = (menu) => {
    return menu.reduce((prev, cur) => {
        if (!cur.parentSlug) {
            const children = createChildren({ menu, parentSlug: cur.slug });
            return [
                ...prev,
                createRouteObject({
                    GUID: cur.id,
                    Title: cur.name,
                    path: `/${cur.locale}/${cur.link || cur.slug}/`,
                    name: cur.name,
                    link: cur.slug,
                    displayInHeader: cur.displayInHeader,
                    language: cur.locale,
                    displayInFooter: cur.displayInFooter,
                    externalUrl: cur.externalUrl,
                    component: () =>
                        import ("../views/Page.vue"),
                    children,
                }),
            ];
        }

        return prev;
    }, []);
};

const addFunctionsToFilters = (filters) => {
    let filtersWithFunctions = {};
    filters.forEach((filter) => {
        const filterName = filter[0];
        const selectedFilters = filter[1];

        filtersWithFunctions = {
            ...filtersWithFunctions,
            [filterName]: (valuesToFilter) => {
                if (!valuesToFilter) return false;

                if (typeof selectedFilters === "string") {
                    // If is a string containing a Number
                    if (!isNaN(selectedFilters)) {
                        return valuesToFilter.some((value) => {
                            return selectedFilters.includes(value);
                        });
                    }
                    // If it's text
                    let result = false;
                    const foundPosition = valuesToFilter
                        .toLowerCase()
                        .search(selectedFilters.toLowerCase());

                    result = foundPosition >= 0 ? true : false;
                    return result;
                }

                if (isArray(valuesToFilter)) {
                    return valuesToFilter.some((value) => {
                        // URL queries will be array of strings so make sure to convert the value
                        return selectedFilters.includes(String(value));
                    });
                }
            },
        };
    });
    return filtersWithFunctions;
};

export const filterArray = ({ dataToFilter, appliedFilters }) => {
    // We need to create an object where each filter is a function

    const filters = addFunctionsToFilters(Object.entries(appliedFilters));

    const filterKeys = Object.keys(filters);
    return dataToFilter.filter((item) => {
        // validates all filter criteria
        return filterKeys.every((filterKey) => {
            // If we have a query key 'Search', look in every value of the item to filter and match the Search={value}
            if (filterKey === "Search") {
                const searchValue = appliedFilters.Search;
                const myArray = searchValue.split(" ");

                return Object.values(item).some((value) => {
                    let result = false;
                    myArray.forEach((element) => {
                        if (String(value).search(element.toLowerCase()) > 0) {
                            result = true;
                            return;
                        }
                    });

                    return result;
                });
            }
            // Filters are also nested i.e ActionSlug.DocumentsSlug
            if (filterKey.includes(".")) {
                const nestedValues = filterKey.split(".");

                const nestedValue = nestedValues.reduce((prev, cur) => {
                    if (isArray(prev)) {
                        return prev.map((el) => el[cur]);
                    }
                    if (cur && prev[cur]) {
                        return prev[cur];
                    }
                    return false;
                }, item);
                if (nestedValue) {
                    return filters[filterKey](nestedValue);
                }
            }

            return filters[filterKey](item[filterKey]);
        });
    });
};