<template>
  <section class="p-5 text-start text-sm-start">
  
      <SectionTitle :PageContent="PageContent" />

      <div class="d-flex justify-content-between">
        <div class="row text-start py-0">
          <div class="col-md-4" v-for="(content, index) in componentData" :key="index">

            <!-- Modal -->
            <div class="modal fade" :id="'exampleModal'+index" tabindex="-1"  aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered ">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 v-if="content-Title" class="card-title fw-bold modal-title" id="staticBackdropLabel">{{content.Title}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                      <span class="visually-hidden-focusable">Close Modal</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <img class="img-fluid" :src="content.url" :alt="content.url" data-bs-toggle="modal" data-bs-target="#exampleModal" />
                  </div>

                </div>
              </div>
            </div>
            
            
            <div class="">
              <a class="img-button  " data-bs-toggle="modal" :data-bs-target="'#exampleModal'+index"  aria-hidden="false" href="#">
              <img class="img-fluid cursor-pointer pb-5 " :src="content.url" :alt="content.url"   />
              </a>
              <h5 class="card-title fw-bold pb-3" v-if="content.Title">
                {{ content.title }}
              </h5>
              <p class="card-title title-description pb-3 fw-bold" v-if="content.Description">
                {{ content.description }}
              </p>
              <p class="card-text" v-html="content.Content"></p>
           
            </div>
          </div>
        </div>
     
      
    </div>
  </section>
</template>

<script >
export default {
  name: "CardThreeCols",
  props: {
    componentData: [],
  },

};


</script>

<style lang="scss" scoped>

img {
  width: 100%;
}

.img-button{
  

  &:focus-visible{
    border: 2px solid var(--brand-dark-grey);
  }
}

.cursor-pointer{
  cursor: pointer;
}

.title-description {
  color: var(--brand-purple);
}

.modal-body {
  padding: 0;
}

@media screen and (max-width: 768px) {
.p-5 {
    padding: 0 !important;
  }


}
</style>
