<template>
  <h4 class=" text-brand-dark-grey fw-bold pb-2 " v-if="title"> {{title}} </h4>
  

</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style>
</style>