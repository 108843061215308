<template>
  <!-- footer -->
  <footer class="p-5 h-300 bg-brand-dark-grey text-white align-items-center overflow-hidden">
    <div class="row justify-content-center align-items-center">
      <div class="col-md-2">

        <ul class="mb-0 " v-for="(content, index) in footerData.filter(c=> c.displayInFooter && c.language ===this.route.params.language) " :key="index">
          <a class="text-white" target="_blank" :href="content.externalUrl" v-if="content.externalUrl">
            {{content?.Title}}
          </a>
          <router-link class="text-white" v-else :to="content.path">
          
            {{content?.Title}}

          </router-link>
        </ul>

      </div>

      <div class="col-md-8 justify-content-center align-items-center text-center">
    
        
        <p class="mb-0">©{{ new Date().getFullYear() }} {{siteData.copyright}}</p>
    
      </div>

      <div class="col-md-2 justify-content-end">

      </div>

    </div>
  
  </footer>
</template>

<script >
import { useRoute } from "vue-router";

export default {
  name: "NavigationBar",
  data() {
    return {
      menu: [],
      route: useRoute(),
      state: "close",
      show: false,
    };
  },

  props: {
    footerData: [],
    siteData:[]
  },
};
</script>


<style lang="scss" scoped>


@media screen and (max-width: 992px) {
  footer {
    padding-left: 1rem !important;
  }
}

li {
  list-style: none;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>