// import menu from "../localData/menu.json";

import { gql, GraphQLClient } from "graphql-request";

const graphQLClient = new GraphQLClient(
  "https://api-eu-west-2.hygraph.com/v2/cla0tslbv4c6l01tb29phbiue/master"
  // "https://api-eu-west-2.hygraph.com/v2/claw8mdtl3i9m01usb5yte2rb/master"
  // "https://api-eu-west-2.hygraph.com/v2/clawpocgv03sj01uke9yd77lr/master"
);

let locales = localStorage.getItem("LanguageId");

export const fetchMenu = async (language) => {
  const query = gql`
    {
      pages(first: 1000, orderBy: orderBy_ASC, locales: ${language}) {
        id
        slug
        name
        parentSlug
        displayInHeader
        displayInFooter
        locale
        externalUrl
      }
    }
  `;

  let data = (await graphQLClient.request(query)).pages;
  return data;
};

export const fetchSearch = async (language) => {
  const query = gql`
    {
      pages(locales: ${language ?? locales}) {
              pageContent {
                ... on AssetsComponent {
               
                  slug
                  title
                  subTitle
                  assets(first:100) {
                    url
                    title
                  }
                }
               
                ... on ListComponent {
                  content
                  slug
                  title
                  listItems(first:100) {
                    title
                    url
                    content
                  }
                }
              }
    
            }
          }
  `;

  //   `
  //   {
  //     assets(first: 1000) {
  //       id
  //       title
  //       url
  //       fileName
  //     }
  //   }
  // `;
  let data = (await graphQLClient.request(query)).pages;
  return data;
};

export const fetchPageContent = async (pageId, language) => {
  const query = gql`
    {
        pages(where: {slug: "${pageId}"}, locales: ${language ?? locales}) {
           
          id
          slug
          name
          parentSlug
          banner {
            fileName
            title
            url
          }
          pageContent(first:100)  {
            ... on AssetsComponent {
              id
              slug
              title
              subTitle
              assets(first:100) {
                url
                title
              }
            }
            ... on TextComponent {
              id
              stage
              title
              slug
              content {
                html
              }
            }
            ... on FaqComponent {
              id
              faqs(first:100) {
                answer {
                  html
                }
                question
              }
              slug
              description
              title
            }
            ... on ImageTextComponent {
              id
              content
              description
              image {
                fileName
                url
              }
            }
         
            ... on ContactComponent {
              id
              contactDetails {
                contactType
                contatDetails(first:100) {
                  ... on LabelValueComponent {
                    id
                    label
                    value
                  }
                }
              }
              slug
              title
              description
            }
            ... on ListComponent {
              id
              content
              slug
              title
              listItems(first:100) {
                title
                url
                content
              }
            }
          }

       
          
          orderBy
        }
      }
        
            
        
  `;
  let data = (await graphQLClient.request(query)).pages;

  return data;
};

export const fetchNewItems = async (language) => {
  const query = gql`
    {
      newsPages(locales: ${language ?? locales}, orderBy: publicationDate_DESC) {
        description
        endDate
        startDate
        publicationDate
        title
        slug
      }
    }
  `;

  let data = await graphQLClient.request(query);
  return data;
};

export const fetchSiteInformation = async (language) => {
  const query = gql`
    {
      siteInformations(locales: ${language}) {
        socialLinks(first:100) {
          url
          title
        }
    

        icons(first:100) {
          title
          url
          customWidth
        }
        copyright
        siteName
      }
      
    }
    
    
  `;

  let data = await graphQLClient.request(query);

  return data;
};

export const fetchNewDetails = async (slug, language) => {
  const query = gql`
    {
      newsPages(where: {slug: "${slug}"}, locales: ${language}) {
      
           description
                endDate
                startDate
                publicationDate
                title
                slug
                isActive
             
        
                contactInformation(first:100) {
                  ... on NewsContactComponent {
                    id
                    email
                    name
                    phone
                  }
                }

                newsDetails(first:100) {
                 
                  ... on TextComponent {
                    id
                    content {
                      html
                    }
                    title
                    slug
                  }
                }
                activities(first:100) {
                      id
                      activity {
                        html
                      }
                    endDate
                    startDate
                    isActive
                    title
                    description
                }
              }
            }
      
  `;

  let data = await graphQLClient.request(query);
  return data.newsPages[0];
};