<template>
  <div class=" text-sm-start list-component pb-5">

    <TitleComponent :title="componentData.title" />

    <div class="lh-base text-justify">

      <p v-html="componentData?.content">
      </p>
      <p v-html="componentData.subTitle">
      </p>
    </div>

    <ul v-for="(content, index) in componentData.assets ?? componentData.listItems " :key="index">
      <template v-if="content?.title || content?.content || content?.url">
      <li v-if="!content.url" :class="{'fw-bold': content.content}">
        {{content?.title}}
      </li>
      <a class="text-brand-blue" target="_blank" :href="content?.url" v-else> {{content?.title}}
        <i class="bi bi-chevron-right icon"></i>
      </a>
      <p>{{content.content}} </p>
    </template>
    </ul>
  </div>

</template>
<script >
export default {
  props: {
    componentData: [],
  },
};
</script>

<style lang="scss"  scoped>
.list-component {
  a {
    text-decoration: none;
    
    &:hover {
      font-weight: bold;
      color: var(--brand-blue) !important;
    }
    .icon {
      vertical-align: text-top;
    }
  }
}
</style>
