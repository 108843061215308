<template>

  <!-- <DatePickerComponent /> -->

  <h5 class="text-brand-dark-grey fw-bold" v-if="timeline"> Bridge Updates
    <a @click="this.router.push(`/${route.params.language}/news/?item=${(timelineData ?? data?.newsPages)[0]?.slug}`)" class="fw-bold text-timeline ps-1 " >
      View More
    </a>
  </h5>

  <div class="timeline ps-3 " :class="{'timeline-scroll': timeline}">
    <ul class="timeline-list">
      <button class="timeline-list-item" v-for="newsTimeline in timelineData ?? data?.newsPages" :key="newsTimeline.slug" @click="this.router.push(`/${route.params.language}/news/?item=${newsTimeline.slug}`)" :class="{'active': newsTimeline.slug == route.query?.item }">
        <span class="text-brand-dark-grey fw-bold" v-if="newsTimeline?.startDate !== ''"> {{ getMonthYear(newsTimeline?.publicationDate)}}</span>
        <div class="content">
          <p v-if="newsTimeline?.title !== ''" class="timeline-title">{{ `${newsTimeline?.title} ${activityDateRange(newsTimeline?.startDate, newsTimeline?.endDate)}`}}</p>
          <p class="timeline-desc" v-html="IntelligentlyTruncate(newsTimeline?.description , 300)"></p>
        </div>
      </button>
    </ul>
  </div>

</template>

<script>
import { useRoute, useRouter } from "vue-router";

import { fetchNewItems } from "@/api";
export default {
  data() {
    return {
      selectedIndex: 0,
      route: useRoute(),
      router: useRouter(),
      routerParam: useRoute().query?.item,
      data: [],
    };
  },

  async mounted() {
    this.data = await fetchNewItems(this.locale);
  },
  props: {
    timelineData: [],
    locale: String,
    timeline:Boolean
  },
  methods: {
    getMonthYear(d) {
      const today = new Date(d);
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
      return yyyy + "-" + mm + "-" + dd;
    },
    activityDateRange(startDate, endDate) {
      const sday = new Date(startDate);
      const eday = new Date(endDate);

      let sm = sday.toLocaleDateString("default", { month: "short" }); // Months start at 0!
      let em =
        sday.toLocaleDateString("default", { month: "short" }) ===
        eday.toLocaleDateString("default", { month: "short" })
          ? null
          : eday.toLocaleDateString("default", { month: "short" }); // Months start at 0!
      let sd = sday.getDate();
      let ed = eday.getDate();

      if (sd < 10) sd = "0" + sd;
      if (ed < 10) ed = "0" + ed;

      return sd === ed
        ? sm + " " + sd
        : sm + " " + sd + " - " + (em ? em + " " : "") + ed;
    },
    IntelligentlyTruncate(text, threshhold = 300) {
  for (let i = threshhold; i < text.length; i++) {
    if (/^\s/.test(text.substr(i, 1))) return text.substr(0, i) + '...'
  }
  return text
}

  },
};
</script>
<style lang="scss">

.text-timeline {
  .text-timeline {
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }
  }
}

.timeline-scroll {
  height: 300px !important;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--brand-white);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--brand-dark-grey);
    border-radius: 38px;
    width: 4px;
  }
}


.timeline {
  overflow-y: scroll;
  overflow: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
  p {
    margin-bottom: 0 !important;
  }

  .timeline-list {
    list-style-type: none;
    border-left: 2px solid var(--brand-dark-grey);
    padding: 0 5px;

    .timeline-list-item {
      padding: 0 20px;
      position: relative;
      cursor: pointer;
      transition: 0.5s;
      padding-bottom: 5px;

      border: none;
      background: none;

      .timeline-title {
        font-weight: bold;
      }

      &:before {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        background-color: var(--brand-dark-grey);
        border-radius: 50%;
        left: -11px;
        top: 10px;
        transition: 0.5s;
      }

      &:hover {
        background-color: var(--shades-50);
        &:before {
          background-color: var(--shades-100);
          box-shadow: 0px 0px 10px 2px var(--shades-100);
        }

        .timeline-desc {
          display: inline-block;
        }
      }

      span {
        display: inline-block;
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .timeline-desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 300px) {
  .timeline {
    width: 100%;
    padding: 30px 5px 30px 10px;
  }
}

.active {
  background-color: var(--shades-200) !important;

  .timeline-desc {
    display: inline-block;
  }
}

@media screen and (max-width: 480px) {
  .timeline {
    height: 300px;
    margin-bottom: 2rem !important;
  }
}
</style>