<template>

  <div class=" p-5 text-sm-start image-position text-timeline">
    <div class="row">

      <div class="col-md-9 lh-base text-justify">
        <TextComponent :componentData="componentData" />
      </div>

      <div class="col-md-3 lh-base">
     

        <TimelineComponent :locale="locale" :key="locale" :timeline="true" />

      </div>
    </div>
  </div>

</template>

<script >
import { breakpointsBootstrapV5, useBreakpoints } from "@vueuse/core";
import { useRoute } from "vue-router";

export default {
  data() {
    return {
      route: useRoute(),
      breakpoints: useBreakpoints(breakpointsBootstrapV5),
      // smAndSmaller: breakpoints.smaller("md")
    };
  },
  props: {
    componentData: [],
    locale: String,
  },
};
</script>

<style lang="scss" scoped>

.text-timeline {
  .text-timeline {
    text-decoration: none;
    font-size: 12px;

    &:hover {
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 992px) {
  .text-timeline {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 1rem !important;
  }
}
</style>