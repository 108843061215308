<template>
  <div v-if="componentData.length > 0" class="text-center text-sm-start  news-contacts">
 
    <h4>Contact details</h4>
    <div class="row" v-for="(contact,index) in componentData" :key="index">
      <div class="col-md-8 mb-3 pb-1 border-bottom">
        <p class="pb-0 mb-0" v-if="contact?.name">{{contact?.name}}</p>
        <div v-for="phone in contact.phone" :key="phone" >
          <a class="text-brand-dark-grey" :href="`tel:${phone}`">{{phone}}</a>
        </div>

        <div v-for="email in contact.email" :key="email" >
          <a class="text-brand-dark-grey" :href="`mailto:${email}`">{{email}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  props: {

    componentData:[]
  },
};

</script>

<style lang="scss">
.news-contacts {
  a {
    text-decoration: none;
    &:hover {
      font-weight: bold;
    }
  }
}
</style>
