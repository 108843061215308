<template>
  <div class="p-5 pt-0 text-center text-sm-start  bridge-facts">
    <div class="row">
      <div class="col-md-3 d-flex justify-content-center align-items-center">
        <img src="@/assets/didyouknow.jpg" alt="" />
      </div>
      <div class="col-md-9">
        <ListComponent :componentData="componentData" :locale="locale" />
      </div>
    </div>

  </div>
</template>
<script >
export default {
  props: {
    componentData: [],
  },
};
</script>
<style lang="scss">
.bridge-facts {
  img {
    max-width: 80%;
  }
}
</style>