import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@vuepic/vue-datepicker/dist/main.css";
import createDynamicRoutes from "./router";
import { createApp } from "vue";
import VueGtag from "vue-gtag";
import { createMenu } from "./helpers";

import { createPinia } from "pinia";

// eslint-disable-next-line no-unused-vars
import router from "./router";
import { useMenuStore, useSiteStore } from "@/store";

import App from "./App.vue";

let app = createApp(App);
const pinia = createPinia();

import NavigationBar from "@/components/NavigationBar.vue";
import TextTimeline from "./components/TextTimeline.vue";
import BreadCrumbs from "./components/BreadCrumbs.vue";
import VueFooter from "./components/VueFooter.vue";
import CardThreeCols from "@/components/layout/CardThreeCols.vue";
import SiteBanner from "@/components/layout/SiteBanner.vue";
import TwoColumnList from "@/components/layout/TwoColumnList";
import TextComponent from "@/components/layout/TextComponent";
import ListComponent from "@/components/layout/ListComponent";
import ContactUs from "./components/ContactUs";
import FaqsComponent from "./components/FaqsComponent";
import TimelineComponent from "./components/TimelineComponent";
// import NewsTimeline from "./components/NewsTimeline";
import BridgeFacts from "./components/BridgeFacts";
import LanguageSelection from "./components/LanguageSelection.vue";
import SearchBar from "./components/SearchBar.vue";
import DatePickerComponent from "./components/DatePickerComponent";
import NewsContacts from "./components/NewsContacts";
import TitleComponent from "./components/layout/TitleComponent";

app.use(pinia);
app.use(VueGtag, {
    config: { id: "" },
    enabled: true,
});

app.component("NavigationBar", NavigationBar);
app.component("TextTimeline", TextTimeline);
app.component("BreadCrumbs", BreadCrumbs);
app.component("VueFooter", VueFooter);
app.component("CardThreeCols", CardThreeCols);
app.component("SiteBanner", SiteBanner);
app.component("TwoColumnList", TwoColumnList);
app.component("TextComponent", TextComponent);
app.component("ListComponent", ListComponent);
app.component("ContactUs", ContactUs);
app.component("FaqsComponent", FaqsComponent);
app.component("TimelineComponent", TimelineComponent);
// app.component("NewsTimeline", NewsTimeline);
app.component("BridgeFacts", BridgeFacts);
app.component("LanguageSelection", LanguageSelection);
app.component("SearchBar", SearchBar);
app.component("DatePickerComponent", DatePickerComponent);
app.component("NewsContacts", NewsContacts);
app.component("TitleComponent", TitleComponent);

createDynamicRoutes().then(({ router, menu, siteInformation }) => {
    app.use(
        VueGtag, {
            config: { id: "" },
            enabled: true,
        },
        router
    );
    setupStore(menu, siteInformation);
    return app.use(router).mount("#app");
});

const setupStore = (menu, siteInformation) => {
    const store = useMenuStore();
    const menuData = JSON.parse(JSON.stringify(createMenu(menu)));
    store.menuData = menuData;

    // Store Social Media Items
    const siteStore = useSiteStore();
    const siteData = JSON.parse(JSON.stringify(siteInformation.siteInformations));
    siteStore.siteInformation = siteData;
};

//Testing CICD