import { fetchMenu, fetchSiteInformation } from "@/api";
import { createRouter, createWebHashHistory } from "vue-router";
// import { home } from "../views/HomeView.vue";

const routes = [{
        path: "/:language/search",
        name: "search",
        component: () =>
            import ("../views/SearchView.vue"),
    },
    {
        path: "/:language/news",
        name: "news",
        component: () =>
            import ("../views/NewsView.vue"),
    },
    {
        path: "/",
        redirect: "/en/home",
        name: "homes",
        component: () =>
            import ("../views/Page.vue"),
    },
    {
        path: "/home",
        redirect: "/en/home",
        name: "home",
        component: () =>
            import ("../views/Page.vue"),
    },
    {
        path: "/home",
        redirect: "/en/home",
        name: "error",
        component: () =>
            import ("../views/Page.vue"),
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () =>
            import ("../views/NotFound.vue"),
    }
];

const createDynamicRoutes = async() => {
    // let menu = await fetchMenu(language());
    // language()
    const Languages = ["en", "fr"];
    let menu = [];
    // await Languages.forEach(async(x) => await menu.push(...(await fetchMenu(x))));
    const siteInformation = await fetchSiteInformation("en");

    // TODO: Refactor with foreach
    menu.push(...(await fetchMenu(Languages[0])));
    menu.push(...(await fetchMenu(Languages[1])));

    const router = await createRouter({
        mode: history,
        history: createWebHashHistory(),
        routes,
    });

    menu.forEach((menuItem) => {
        if (menuItem.parentSlug) {
            // The first arg to addRoute fn is the parent name which must match, otherwise path must start with a '/'
            router.addRoute(`${menuItem.parentSlug}__${menuItem.id}`, {
                path: `/:language/${menuItem.parentSlug}/${menuItem.slug}`,
                name: `${menuItem.slug}__${menuItem.id}`,
                component: () =>
                    import ("../views/Page.vue"),
            });
            return;
        }

        // Parent Route or High level
        router.addRoute({
            path: `/:language/${menuItem.slug}`,
            name: `${menuItem.slug}__${menuItem.id}`,
            component: () =>
                import ("../views/Page.vue"),
        });
    });

    return { router, menu, siteInformation };
};
export default createDynamicRoutes;