import { defineStore } from "pinia";

export const useMenuStore = defineStore("menu", {
    state: () => ({
        menuData: [],
    }),
    getters: {
        // getMenu(state) {
        //     console.log('state.menu', state)
        //     return state.menuData;
        // },
    },
    actions: {
        addItem(menuData) {
            this.menuData.push(menuData);
        },
    },
});

export const useSiteStore = defineStore("site", {
    state: () => ({
        logo: {},
        siteInformation: [],
        socialLinks: [],
    }),
    getters: {
        // getMenu(state) {
        //     console.log('state.menu', state)
        //     return state.menuData;
        // },
    },
    actions: {
        addItem(siteInformation) {
            // this.logo:{ }
            console.log("siteInformation", siteInformation);
            this.logo = this.siteInformation[0].logo;
            this.socialLinks.push(siteInformation.socialLinks);
            this.socialLinks.push(siteInformation);
        },
    },
});