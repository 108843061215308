<template>
  <div class="d-flex flex-row mb-40" :class="{'mb-80': locales.length<2 }">
    <nav class="navbar navbar-expand-xl custom-nav bg-white navbar-light fixed-top ps-5 d-flex align-items-center pt-3 " id="top">
      <div>
        <router-link v-for="site, index in siteLogo.filter(c=> c.title==='logo')" :key="index" :to="`/${language}/home`" class="navbar-brand text-brand-dark-grey primary">
          <img :src="site.url" :style="`width:${site?.customWidth ?? '120'}px`" :alt="site.title">
        </router-link>
      </div>
      <button aria-label="Toggle navigation" id="navbar-toggler" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navmenu" @click="visible=!visible">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse " id="navmenu" :class="{'collapse':!visible,' border-end' : locales.length>1 }">
        <ul class="navbar-nav ms-auto">
          <NavigationBar :languageCode="language" :key="language" @clicked="menuChange" />

        </ul>

      </div>
    </nav>

    <LanguageSelection @clicked="languageChange" :locales="locales" v-if="(locales.length>1)" />

  </div>

</template>

<script  >
import { useSiteStore } from "@/store";


export default {
  data() {
    return {
      language: "en",
      visible: false,
      locales: [
        { code: "en", language: "EN" },
         { code: "fr", language: "FR" },
      ],
    };
  },

  computed: {
    siteLogo() {
      return useSiteStore().siteInformation[0].icons;
    },
  },
  methods: {
    languageChange(value) {
      this.language = value;
    },
    menuChange(value) {
      this.visible = value;
    },
 
  },
};
</script>


<style lang="scss">
#top {
  padding-left: 4.5rem !important;
}
@media screen and (max-width: 992px) {
  #top {
    padding-left: 1rem !important;
  }
}

.navbar {
  padding-right: 4rem !important;

  .navbar-brand {
    font-size: 28px !important;
  }
}

@media screen and (max-width: 1200px) {
  ul.dropdown-menu {
    padding: 0 !important;
    margin-top: 0 !important;
    &::before {
      content: none !important;
    }
  }

  #navmenu {
    border-right: none !important;
    padding-top: 1rem !important;
  }
}
</style>
